<template>
  <div>
    <v-container class="max-w-100">
      <v-row class="adjust-input-height">
        <!-- <v-btn @click="startAnnotate"> Annotate</v-btn> -->
        <v-col cols="12" sm="12" md="6" lg="4">
          <v-autocomplete
            label="Book"
            :items="booklist"
            item-title="name"
            item-value="key"
            v-model="book"
            variant="outlined"
          ></v-autocomplete>
          <div class="mb-5">
            <a target="_blank" :href="selectedBookUrl">{{
              selectedBookName
            }}</a>
          </div>
          <div class="mb-5">
            <v-text-field
              label="Book url"
              v-model="bookurl"
              variant="outlined"
            ></v-text-field>

          </div>
          <div class="d-flex">
            <v-text-field
              class="mr-3"
              label="Page number"
              v-model="page"
              variant="outlined"
            ></v-text-field>
            <v-text-field
              label="Image Width"
              v-model="imagewidth"
              variant="outlined"
            ></v-text-field>
            <v-btn
              class="min-h-46 ml-3"
              @click="getBookPage"
              :loading="getpageloading"
              :disabled="!(book || bookurl) || !page || !imagewidth"
              >Get</v-btn
            >
            <!-- <v-btn class="min-h-46 ml-3">Image</v-btn> -->
          </div>
          <!-- <div class="d-flex">
            <v-text-field label="Out url" variant="outlined"></v-text-field>
            <v-btn class="min-h-46 ml-3"><v-icon>mdi-clipboard</v-icon></v-btn>
          </div> -->
          <div class="mb-10">
            <v-btn :disabled="!imgsrc" class="mr-4 min-h-46" @click="getResult"
              >Crop</v-btn
            >
            <v-btn
              :disabled="cropping || annotating || !imgsrc"
              class="min-h-46"
              @click="startAnnotate"
            >
              Annotate</v-btn
            >
            <v-btn
              :disabled="cropping || annotating || !imgsrc"
              class="min-h-46 ml-2"
              @click="translateImage"
            >
              Translate</v-btn
            >            
            <v-btn
              :disabled="cropping || annotating || !imgsrc"
              class="ml-15 min-h-46"
              @click="saveRef"
              >Save</v-btn
            >
          </div>
          <div class="mt-10">
            <!-- text area for translation -->
            <v-textarea
              v-if="translation"
              v-model="translation"
              
              outlined
              rows="15"
            ></v-textarea>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="8">
          <img
            ref="imgRef"
            v-if="imgsrc && cropping === false && annotating === false"
            style="max-width: 100%"
            @click="showMarkerArea"
            :src="imgsrc"
            alt=""
            class="drawingimage"
          />
          <div v-if="cropping">
            <VuePictureCropper
              :boxStyle="{
                width: '100%',
                height: '100%',
                backgroundColor: '#f8f8f8',
                margin: 'auto',
              }"
              :img="imgsrc"
              :options="{
                viewMode: 1,
                dragMode: 'crop',
                zoomable: false,
                autoCrop: false,
                // aspectRatio: 16 / 9,
              }"
              @ready="ready"
            />
          </div>
          <div class="drawingimage" v-if="!imgsrc">
            <div class="file-upload-wrapper">
              <label for="file-upload" class="custom-file-upload">
                Click to upload or select from library on the left.
              </label>
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                @change="handleImageUpload"
              />
              <div class="image-preview">
                <img
                  v-if="imagePreview"
                  :src="imagePreview"
                  alt="Uploaded Image"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { post, get } from "@/apiClient";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import * as markerjs2 from "markerjs2";
// import VueImageAnnotation from 'vue-image-annotation';
export default {
  components: {
    VuePictureCropper,
    // VueImageAnnotation,
    // cropper,
  },
  props: {
    fieldtext: {
      type: Object,
    },
    updatefunc: {
      type: Function,
    },
    field: {
      type: String,
    },
    scrollposition: {
      type: Number,
    },
  },
  data: () => ({
    booklist: [],
    book: "",
    page: "",
    bookurl: "",
    imgsrc: "",
    imagewidth: 600,
    cropping: false,
    annotating: false,
    getpageloading: false,
    imagePreview: null,
    translation: "",
  }),
  mounted: async function () {
    // console.log(this.fieldtext);
    get("library/getbooklist")
      .then((res) => {
        // sort data by name
        res.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          else if (a.name > b.name) return 1;
          else return 0;
        });
        this.booklist = res.data;
      })
      .catch((err) => console.log(err));
  },
  computed: {
    selectedBookUrl() {
      return (
        this.booklist.find((book) => book.key === this.book)?.link +
        (this.page ? `#page=${this.page}` : "")
      );
    },
    selectedBookName() {
      return this.booklist.find((book) => book.key === this.book)?.name;
    },
  },
  methods: {
    translateImage: async function () {
      post("library/translateimage", {
        image: this.imgsrc.split("base64,")[1],
      })
        .then((response) => {
            // console.log(response.data);
          this.translation = response.data;
          // this.imgsrc = response.data;
        })
        .catch((err) => console.log(err));
    },
    saveRef: async function () {
      post("library/saveref", {
        image: this.imgsrc,
      })
        .then((response) => {
          //   console.log(data);
          let reftext = `\n\n<br> <img src="${response.data.url}" class="mdimg" />`;
          if (this.book) {
            let bookobj = this.booklist.find((book) => book.key === this.book);
            //\n\n ###### <a href="">The ref here</a> \n\n
            //https://web.archive.org/web/https://www.africau.edu/images/default/sample.pdf#page=2
            // console.log(this.page);
            // date format 20221222063246 number of digits 14
            let datetime = new Date().toISOString().replace(/[^0-9]/g, "").slice(0, 14);
            
            reftext += `\n\n ###### [<a target="_blank" href="https://web.archive.org/web/${datetime}/${
              bookobj.link
            }${this.page ? "#page=" + this.page : ""}">${bookobj.name} ${
              this.page ? "Page " + this.page + " in pdf" : ""
            }</a>] \n\n
            ${this.translation} \n\n
            `;
          } else {
            reftext += `\n\n ###### [Provide Reference] \n\n`;
          }
          console.log(reftext);
          this.updatefunc(this.fieldtext, this.fieldtext[this.field] + reftext);
        })
        .catch((err) => console.log(err));
    },
    imageToJpeg: function (img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      return canvas.toDataURL("image/jpeg");
    },
    getBookPage: async function () {
      this.translation = "";
      this.getpageloading = true;
      post("library/getbookpage", {
        book: this.book,
        page: this.page,
        imagewidth: this.imagewidth,
        bookurl: this.bookurl,
      })
        .then((data) => {
          //   console.log(data);
          //   this.getpageloading = false;
          //   this.imgsrc = data.data;
          // change to jpeg
          var img = new Image();
          img.src = data.data;
          img.onload = () => {
            this.imgsrc = this.imageToJpeg(img);
            this.getpageloading = false;
          };
        })
        .catch((err) => {
          this.getpageloading = false;
          console.log(err);
        });
    },
    async getResult() {
      if (!this.cropping) {
        this.cropping = true;
        return;
      }
      if (!cropper) return;
      const base64 = cropper.getDataURL();
      const blob = await cropper.getBlob();
      if (!blob) return;

      const file = await cropper.getFile({
        fileName: "cropped.png",
      });

      this.imgsrc = base64;
      this.cropping = false;
      //   this.result.blobURL = URL.createObjectURL(blob)
      //   this.isShowModal = false
    },
    startAnnotate() {
      let elements = document.getElementsByClassName("__markerjs2_");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }

      const markerArea = new markerjs2.MarkerArea(this.$refs.imgRef);
      markerArea.addEventListener("render", (event) => {
        this.imgsrc = event.dataUrl;
      });
      markerArea.show();
    //   let markerAreajs2 = document.getElementsByClassName("__markerjs2_")[0];
    //   markerAreajs2.style.top = this.scrollposition + 36 + "px";
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // convert to jpeg
          let img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            this.imgsrc = this.imageToJpeg(img);
          };
          //   this.imgsrc = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.imagePreview = null;
      }
    },
  },
  $refs: {
    imgRef: HTMLImageElement,
  },
};
</script>

<style scoped>
.drawingimage {
  border: 1px solid #ccc;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.file-upload-wrapper {
  width: 100%;
  display: flex;
  padding: 5px 10px;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 160px);
}
.file-upload-wrapper .custom-file-upload {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.file-upload-wrapper input[type="file"] {
  display: none;
}
.file-upload-wrapper .image-preview {
  width: 100%;
}
.file-upload-wrapper .image-preview img {
  width: 100%;
}
</style>